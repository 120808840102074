<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="productModal"
    >   
        <CForm>
          <CRow>
            <CCol md="8">
              <CSelect
                label="Código SAT: "
                :isValid="checkIfValid('product_code')"
                :value.sync="$v.form.product_code.$model"
                :options="productsOptions"
              />
            </CCol>
            <CCol md="4">
              <CSelect
                label="Unidad: "
                :isValid="checkIfValid('unit_code')"
                :value.sync="$v.form.unit_code.$model"
                :options="unitsOptions"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="8">
              <CInput
                label="Nombre:"
                :lazy="false"
                :value.sync="$v.form.name.$model"
                :isValid="checkIfValid('name')"
                placeholder=""
                autocomplete="name"
                invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
              />
            </CCol>
            <CCol md="4">
              <CInput
                label="Precio:"
                :lazy="false"
                :value.sync="$v.form.price.$model"
                :isValid="checkIfValid('price')"
                placeholder=""
                autocomplete="price"
                invalidFeedback="Este campo debe ser un valor numérico."
              />
            </CCol>
          </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, decimal, minValue} from "vuelidate/lib/validators"
import units_ws from '../../services/units';
import products_ws from '../../services/fiscal-products';

export default {
  name: 'ProductModal',
  props: {

  },
  
  data () {
    return {
      productModal: false,
      title: "Nuevo Producto",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      units: [],
      unitsOptions: [],
      products: [],
      productsOptions: [],
    }
  },
  mounted: async function() {
    let response = await units_ws.get(); 

    if(response.type == "success"){
      this.units = response.data;
    }

    let response2 = await products_ws.get(); 

    if(response2.type == "success"){
      this.products = response2.data;
    }

    this.unitsOptions = await this.$parseSelectOptionsOrdered(this.units, "code", "code:name");
    this.productsOptions = await this.$parseSelectOptionsOrdered(this.products, "sat_code", "sat_code:name");
  },
  methods: {
    storeModal () {
      this.productModal = true;
      this.color = "info";
      this.title = 'Nuevo Producto';
      this.isEdit = false;
      this.submitted = false;

      this.form = this.getEmptyForm();
    },
    updateModal (item) {
      this.productModal = true;
      this.color = "warning";
      this.title = 'Editar Producto';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
          id: item.id,
          name: item.name,
          description: item.description,
          price: item.price > 0 ? this.$options.filters.decimal(item.price) : '',
          currency: item.currency,
          exchange_rate: item.exchange_rate,
          product_code: item.product_code,
          unit_code: item.unit_code,
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(!(this.form.price > 0)){
          this.form.price = 0.00;
        }

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.productModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          name: '',
          description: '',
          price: '',
          currency: 'MXN',
          exchange_rate: 1.0000,
          product_code: "01010101",
          unit_code: "E48",
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      unit_code: {
        required
      },
      product_code: {
        required
      },
      name: {
        required,
        minLength: minLength(2)
      },
      description: {},
      price: {
        decimal
      },
      currency: {},
      exchange_rate: {},
    }
  },
}
</script>